export const errorHTML = `<html>
<head>
    <title></title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    
</head>
<body>
  <p>Ошибка! Электронная почта не подтверждена!</p>

</body>
</html>
`

export const successHTML = `<html>
<head>
    <title></title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
  
</head>
<body>
  <p>Электронная почта успешно подтверждена!</p>
</body>
</html>`

export const alreadyHTML = `<html>
<head>
    <title></title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
   
</head>
<body>
 <p>Ошибка! Электронная почта уже подтверждена!</p>
   
</body>
</html>
`