import config from '../config';

const fetchStatusCheckEmail = async (requestData) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: Object.entries(requestData)
        .map(e => e.join('='))
        .join('&')
    };

    return fetch(`${config.api.baseUrl}?class=CUSTOMERS&action=VERIFICATEEMAIL`,requestOptions)
    .then((response) => {
        return response.json()
    })
    .catch((err) => {
      console.log(err.message);
      return "ERROR";
    })
};

export {
    fetchStatusCheckEmail
}