import { useCallback, useEffect, useState } from 'react';
import { fetchStatusCheckEmail } from './api/request';
import './App.css';
import { errorHTML, successHTML, alreadyHTML } from './constants'

function App() {
  
  const [result, setResult] = useState({
    status: "ERROR",
    text: "",
    template: errorHTML
  });
  
  const prepareResult = useCallback(
    (data) => {
      setResult({
        status: data.result[0]?.status,
        text: data.result[0]?.text,
        template: data.result[0]?.status === "OK" ? successHTML : data.result[0]?.status === "ALREADY" ? alreadyHTML : errorHTML
      })
  },[]);
    
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
   
    const data = {
      code: code
    }

    fetchStatusCheckEmail(data).then((req) => {
      if (data.result !== "OK") prepareResult(req)
    })
  }, []);


  return (
    <div className="App">
      <div dangerouslySetInnerHTML={{ __html: result.template }}></div>
      {/* <header className="App-header">
        <p>
          {result.text}
        </p>
      </header> */}
    </div>
  );
}

export default App;
